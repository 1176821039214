/*  
	CSS Reset by Eric Meyer - Released under Public Domain
    http://meyerweb.com/eric/tools/css/reset/
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
font,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
}
body {
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
:focus {
  outline: 0;
}
ins {
  text-decoration: none;
}
del {
  text-decoration: line-through;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-weight: normal;
  font-style: normal;
}

code {
  font-family: 'Roboto Mono', source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

html,
body,
#root,
#root > div {
  height: 100%;
}

/** To prevent mobile scrolling issues */
html {
  height: 100%;
  /* overflow: hidden; */
}
body {
  height: 100%;
  /* overflow: auto; */
  -webkit-overflow-scrolling: touch;
}
/***/

a {
  text-decoration: none;
}

div[flex] {
  flex: 1;
}

* {
  box-sizing: border-box;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

/* @font-face {
  font-family: 'LocalMarket-Bold';
  src: url('https://firebasestorage.googleapis.com/v0/b/torii-dev-tt.appspot.com/o/font%2FLocalMarket-Bold.otf?alt=media');
  font-weight: normal;
  font-style: normal;
} */

.swiper-container {
  height: 100%;
}
